<template>
  <div class="admin-container">
    <v-dialog v-model="card.show" persistent :width="500" mobile>
      <v-card>
        <v-card-title class="d-flex justify-space-between headline">
          <span>
            {{ card.type === "edit" ? "Edit Card" : "Add Card" }}
          </span>
          <v-icon color="#000" @click="closeModal()">fa fa-times</v-icon>
        </v-card-title>
        <v-card-text class="mt-5">
          <validation-observer ref="cardsObserver" v-slot="{ handleSubmit }">
            <v-form
              ref="cardsForm"
              @submit.prevent="handleSubmit(addEditCards)"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Card Name"
                rules="required"
              >
                <v-text-field
                  v-model="card.cardTypeName"
                  class="form-control mb-2"
                  :error-messages="errors"
                  required
                  outlined
                  dark
                  label="Card Name"
                  background-color="#f0f1f6"
                  color="#000"
                ></v-text-field>
              </validation-provider>
              <div class="d-flex justify-space-between">
                <validation-provider
                  v-slot="{ errors }"
                  name="Active Card"
                  rules="required"
                >
                  <v-checkbox
                    v-model="card.active"
                    :error-messages="errors"
                    :true-value="true"
                    :false-value="false"
                    label="Active Card"
                    class="mt-n2"
                  ></v-checkbox>
                </validation-provider>
                <v-checkbox
                  v-if="card.id && isSuperAdmin"
                  v-model="card.isDelete"
                  :true-value="true"
                  :false-value="false"
                  label="Delete Card"
                  class="mt-n2"
                ></v-checkbox>
              </div>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  :disabled="loading"
                >
                  {{ card.type === "edit" ? "Save" : "Add" }}
                </v-btn>
                <v-btn color="#000" class="white--text" @click="closeModal()">
                  No
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-column flex-sm-row align-center justify-space-between"
        >
          <span class="display-1 text-sm-h3 font-weight-bold">Cards</span>
          <v-btn
            class="mt-2 mt-sm-0"
            color="primary"
            @click="openModal({}, 'add')"
          >
            Add Card <i class="fa fa-plus ml-1"></i>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card>
            <v-data-table
              :headers="registerHeader"
              :items="cards"
              class="elevation-1"
              :loading="dataLoading"
              must-sort
            >
              <template v-slot:[`header.name`]="{ header }">
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:[`item.createdDate`]="{ item }">
                {{ item.createdDate.substring(0, 10) }}
              </template>
              <template v-slot:[`item.active`]="{ item }">
                {{ item.active ? "Yes" : "No" }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal(item, 'edit')"
                    >
                      <v-icon color="primary">fa fa-edit</v-icon></v-btn
                    >
                  </template>
                  Edit Category
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import HomeService from "@/services/HomeService";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      registerHeader: [
        { text: "Card Name", value: "cardTypeName" },
        { text: "Added By", value: "addedByName" },
        { text: "Is Active", value: "active" },
        { text: "Created On", value: "createdDate" },
        { text: "Actions", value: "action", width: 100, sortable: false },
      ],
      card: {
        show: false,
        cardTypeName: null,
        active: false,
        isDelete: false,
        id: 0,
        type: null,
      },
      loading: false,
    };
  },
  async created() {
    if (this.cards.length === 0) {
      await this.$store.dispatch("receipt/FETCH_CARDS");
    }
  },
  computed: {
    ...mapState({
      dataLoading: (state) => state.receipt.loading,
      cards: (state) => state.receipt.cards,
      isSuperAdmin: (state) => state.auth.roleName === "Super Admin",
    }),
  },
  methods: {
    openModal(item, type) {
      this.card = {
        ...item,
        show: true,
        cardTypeName: item.cardTypeName,
        active: item.active,
        type: type,
        id: type === "add" ? 0 : item.id,
      };
    },
    addEditCards() {
      this.loading = true;
      let card = JSON.parse(JSON.stringify(this.card));
      delete card.show;
      delete card.type;
      HomeService.addEditCard(card)
        .then(async (res) => {
          this.loading = false;
          let alertMsg;
          if (this.card.type === "add") {
            alertMsg = "Card Created SuccessFully";
          } else if (this.card.isDelete) {
            alertMsg = "Card Deleted SuccessFully";
            this.$store.dispatch("receipt/FETCH_CARDS");
          } else {
            alertMsg = "Card Updated SuccessFully";
          }
          await this.$store.dispatch("notifications/setNotification", {
            type: "success",
            text: alertMsg,
          });
          if (this.card.type === "add") {
            this.$store.commit("receipt/addCard", res.data.data);
          } else {
            this.$store.commit("receipt/editCard", res.data.data);
          }
          this.closeModal();
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    closeModal() {
      this.card = {
        show: false,
        cardTypeName: null,
        active: false,
        isDelete: false,
        type: null,
        id: 0,
      };
      this.$refs.cardsObserver.reset();
    },
  },
};
</script>
